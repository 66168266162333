import * as React from "react";
import { navigate, Link } from "gatsby";
// import { graphql } from 'gatsby'

import CustomHead from "../../components/common/Head";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import Button from "../../components/common/Button";
import Label from "../../components/common/Label";
import Shape from "../../components/common/Shape";

// const query = graphql``

const ThankYouPage = () => {
  return (
    <Layout>
      <Section
        title="Welcome aboard!"
        intro="You’ve successfully signed up for CodeCaravan updates. We’ll be in touch when new projects or learning opportunities become available."
      >
        <div className="flex items-center gap-4">
          <Button action={() => navigate(-1)}>Go back to where I was</Button>
          <Label>Or</Label>

          <Link to="/">
            <Label className="underline cursor-pointer">
              Back to the homepage
            </Label>
          </Link>
        </div>
        <div className="w-1/3 ml-auto">
          <Shape type="circle" className="text-apricot" />
        </div>
      </Section>
    </Layout>
  );
};

export const Head = () => {
  const pageMeta = {
    title: "Thank you for subscribing",
    description:
      "You’ve successfully signed up to the CodeCaravan mailing list. We’ll be in touch occasionally when new projects or learning opportunities become available.",
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default ThankYouPage;
